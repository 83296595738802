import * as React from "react"
import isEqual from "lodash.isequal"
import { StoreContext } from "../context/store-context"
import { formatPrice } from "../utils/format-price"
import { AddToCart } from "../components/add-to-cart"
import {
  productBox,
  container,
  meta,
  essentials,
  header,
  productImageWrapper,
  optionsWrapper,
  priceValue,
  selectVariant,
  swatch,
  postCta,
  description,
} from "./product-page.module.css"

export default function Essentials({ product }) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    descriptionHtml,
    featuredMedia,
    images,
    tags,
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
          console.log(result.available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1

  const post_cta = tags.find(tag => (tag.includes("post-cta:"))).replace("post-cta:", "")
  console.log(post_cta)

	return (
      <div className={container}>
        <div className={productBox}>
          <div className={productImageWrapper}>
            <video autoPlay muted loop playsInline poster={images[0].originalSrc}>
              <source src={featuredMedia.alt} type="video/mp4"></source>
            </video>
          </div>
          <div className={meta}>
            <h1 className={header}>{title}</h1>
            <h2 className={priceValue}>
              <span>{price}</span>
            </h2>
            <div className={description} dangerouslySetInnerHTML={{ __html: descriptionHtml }}/>
          </div>
          <div className={essentials}>
            <fieldset className={optionsWrapper}>
              {hasVariants &&
                options.map(({ id, name, values }, index) => (
                  <div className={selectVariant} key={id}>
                    <span>{name}</span>
                    <div
                      aria-label="Variants"
                    >
                      {values.map((value) => (
                        <div className={swatch}>
                          <input
                            type="radio"
                            name={name}
                            id={value}
                            value={value}
                            onChange={(event) => handleOptionChange(index, event)}
                          />
                          <label for={value} key={`${name}-${value}`}>
                            {value}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </fieldset>
              <AddToCart
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={available}
              />
              <p className={postCta}>{post_cta}</p>
          </div>
        </div>
      </div>
	)
}