import React from "react";
import Logo from "../icons/logo-2"
import SubscribeForm from "./subscribe-form";
import {
	archiveWrapper,
	video,
	header,
	logo,
	seg
} from "./product-archive.module.css"

export default function Archive({ products }) {
	return (
		<div className={archiveWrapper}>
			<div className={seg}>
				<div className={logo}>
					<Logo />
				</div>
				<h1 className={header}>More to come</h1>
			</div>
			{products.length > 0 && <div className={seg}>
				<h2 className={header}>Archive</h2>
				{products.map(({ featuredMedia, images }, i) => {
					return (
						<video autoPlay muted loop playsInline className={video} poster={images[0].originalSrc}>
							<source src={featuredMedia.alt} type="video/mp4"></source>
						</video>
					)
				})}
			</div>}
			<div className={seg}>
				<h2 className={header}>Stay up to date with upcoming projects</h2>
				<SubscribeForm />
			</div>
		</div>
	)
}