import * as React from "react"
import { graphql, Link } from "gatsby"
import { formatPrice } from "../utils/format-price"
import {
  productCardStyle,
  productHeadingStyle,
  productImageStyle,
  productDetailsStyle,
  productPrice,
} from "./product-card.module.css"

export function ProductCard({ product, set, eager }) {
  const {
    title,
    featuredMedia,
    images,
    priceRangeV2,
  } = product

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )

  return (
    <button
      className={productCardStyle}
      // to={slug}
      onClick={() => set(product)}
      aria-label={`View ${title} product page`}
    >
      <video autoPlay muted loop playsInline className={productImageStyle} poster={images[0].originalSrc}>
        <source src={featuredMedia.alt} type="video/mp4"></source>
      </video>
      <div className={productDetailsStyle}>
        <h2 as="h2" className={productHeadingStyle}>
          {title}
        </h2>
        <div className={productPrice}>{price}</div>
      </div>
    </button>
  )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    title
    images {
      id
      altText
      gatsbyImageData(aspectRatio: 1, width: 640)
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    vendor
  }
`
