import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import ProductEssentials from "../components/product-essentials"
import ProductArchive from "../components/product-archive"
import { ProductListing } from "../components/product-listing"

export default function Product({ data: { products: { nodes } } }) {
  const activeProducts = []
  const archiveProducts = []

  nodes.forEach(node => {
    let now = new Date()
    let start = node.metafields.find(f => f.key === 'release_start')
    let end = node.metafields.find(f => f.key === 'release_end')

    if (start) start = new Date(start.value)
    if (end) end = new Date(end.value)

    if (start < now && now < end) activeProducts.push(node)
    if (end < now) archiveProducts.push(node)
  })

  if (activeProducts.length > 1) {
    return (
      <Layout>
        <ProductListing products={activeProducts} />
      </Layout>
    )
  } else if (activeProducts.length > 0) {
    return (
      <Layout>
        <ProductEssentials product={activeProducts[0]} />
      </Layout>
    )
  } else {
    return (
      <Layout>
        <ProductArchive products={archiveProducts} />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    products: allShopifyProduct(filter: {status: {eq: "ACTIVE"}}) {
      nodes {
        title
        productType
        description
        descriptionHtml
        tags
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        storefrontId
        images {
          originalSrc
        }
        featuredMedia {
          alt
          mediaContentType
          preview {
            status
            image {
              originalSrc
            }
          }
        }
        variants {
          availableForSale
          inventoryQuantity
          storefrontId
          title
          price
          selectedOptions {
            name
            value
          }
        }
        options {
          name
          values
          id
        }
        metafields {
          value
          key
        }
      }
    }
  }
`
