import React, { useState } from 'react';
import { addToCart as addToCartStyle } from "./add-to-cart.module.css"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function SubscribeForm() {
  const [state, setState] = React.useState({})
	const [formVisibility, setFormVisibility] = useState(true)

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
			.then(() => {
				setFormVisibility(false)
			})
      .catch((error) => alert(error))
  }

	if (formVisibility) {
		return (
			<form 
				name="subscribeForm" 
				method="post"
				action="/success"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				onSubmit={handleSubmit}
				id="subscribe-form"
				style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
				className="subscribe-form">
				<input type="hidden" name="form-name" value="subscribeForm" />
				<p hidden>
					<label>
						Don’t fill this out: <input name="bot-field" />
					</label>
				</p>
				<div className="form-row" style={{maxWidth: "360px", margin: "2rem auto 20px"}}>
					<label>
						<span className="screen-reader-text" style={{ display: 'none' }}>Email address</span>
						<input type="email" name="email"
							style={{ border: '1px solid gray', padding: '0.5rem 1rem', borderRadius: '1px' }}
							required placeholder="Enter Email Address..." onChange={handleChange} />
					</label>
				</div>
				<button className={addToCartStyle} style={{ alignSelf: 'center' }} type="submit">Subscribe</button>
			</form>
		);
	} else {
		return (
			<p>Thank you! Have a nice day ☺︎</p>
		)
	}
}