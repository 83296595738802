// extracted by mini-css-extract-plugin
export var productBox = "product-page-module--productBox--1g2cZ";
export var container = "product-page-module--container--1BsOW";
export var meta = "product-page-module--meta--uYEoy";
export var header = "product-page-module--header--3cA7a";
export var essentials = "product-page-module--essentials--1gHkY";
export var productDescription = "product-page-module--productDescription--3PxvH";
export var productImageWrapper = "product-page-module--productImageWrapper--2Ojtf";
export var productImageList = "product-page-module--productImageList--2Yrsz";
export var productImageListItem = "product-page-module--productImageListItem--2HCnb";
export var scrollForMore = "product-page-module--scrollForMore--qq7vX";
export var noImagePreview = "product-page-module--noImagePreview--1Hj93";
export var postCta = "product-page-module--postCta--3wLE4";
export var priceValue = "product-page-module--priceValue--3MlLH";
export var description = "product-page-module--description--1qAl8";
export var visuallyHidden = "product-page-module--visuallyHidden--1v1Mj";
export var optionsWrapper = "product-page-module--optionsWrapper--NWTfz";
export var addToCartStyle = "product-page-module--addToCartStyle--e3S11";
export var selectVariant = "product-page-module--selectVariant--3LKFo";
export var swatch = "product-page-module--swatch--3YT4z";
export var labelFont = "product-page-module--labelFont--Ggdow";
export var tagList = "product-page-module--tagList--1u4F4";
export var breadcrumb = "product-page-module--breadcrumb--3hMiY";
export var metaSection = "product-page-module--metaSection--2CEqM";