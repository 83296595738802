import * as React from "react"
import { ProductCard } from "./product-card"
import ProductEssentials from "./product-essentials"
import { listingContainerStyle, backButton } from "./product-listing.module.css"

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export function ProductListing({ products = [] }) {
  const [activeProduct, setActiveProduct] = React.useState(false)
  if (activeProduct) {
    return (
      <section>
        <button className={backButton} onClick={() => setActiveProduct(false)}>{"<"} Back</button>
        <ProductEssentials product={activeProduct} />
      </section>
    )
  } else {
    return (
      <div className={listingContainerStyle}>
        {products.map((p, index) => (
          <ProductCard product={p} set={setActiveProduct} key={p.id} eager={index === 0}  />
        ))}
      </div>
    )
  }
}
